import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class LocalService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
  }

  public saveData(key: string, value: string) {
    if (isPlatformBrowser(this.platformId))
      localStorage.setItem(key, value);
  }

  public getData(key: string) {
    if (isPlatformBrowser(this.platformId))
      return localStorage.getItem(key);
    return null;
  }

  public removeData(key: string) {
    if (isPlatformBrowser(this.platformId))
      localStorage.removeItem(key);
  }

  public clearData() {
    if (isPlatformBrowser(this.platformId))
      localStorage.clear();
  }
}
